{
  "name": "bsee-shared-hmd",
  "version": "2022.12.2",
  "description": "help me decide shared component",
  "source": "src/index.pug",
  "scripts": {
    "prepare": "husky install",
    "clean": "rimraf build",
    "build": "rimraf build && parcel build src/index.pug",
    "build:dev": "parcel build",
    "build:qa": "parcel build",
    "build:pqa": "parcel build",
    "build:stg": "parcel build",
    "build:perf": "parcel build",
    "build:beta": "parcel build",
    "build:prod": "parcel build",
    "start": "parcel src/index.pug --open",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix",
    "prettify": "prettier --write \"**/*.{css,js,html,md,scss,ts.tsx}\"",
    "fix": "npm run lint:fix && npm run prettify",
    "test": "jest",
    "test:ci": "jest --coverage"
  },
  "repository": {
    "type": "git",
    "url": "git@github.comcast.com:BSD-Digital/bsee-shared-hmd.git"
  },
  "keywords": [
    "hmd",
    "helpMeDecide"
  ],
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "@parcel/config-default": "^2.7.0",
    "@parcel/packager-ts": "^2.7.0",
    "@parcel/transformer-inline-string": "^2.7.0",
    "@parcel/transformer-pug": "^2.7.0",
    "@parcel/transformer-typescript-tsc": "^2.7.0",
    "@parcel/transformer-typescript-types": "^2.7.0",
    "@swc/core": "^1.2.135",
    "@swc/jest": "^0.2.17",
    "@tsconfig/recommended": "^1.0.1",
    "@types/jest": "^27.4.0",
    "@typescript-eslint/eslint-plugin": "^5.10.2",
    "@typescript-eslint/parser": "^5.10.2",
    "eslint": "^8.8.0",
    "eslint-config-prettier": "^8.3.0",
    "eslint-import-resolver-typescript": "^2.5.0",
    "eslint-plugin-import": "^2.25.4",
    "eslint-plugin-prettier": "^4.0.0",
    "husky": "^7.0.0",
    "jest": "^27.4.7",
    "lint-staged": "^12.3.2",
    "parcel": "^2.7.0",
    "parcel-namer-custom": "^0.2.0",
    "prettier": "2.5.1",
    "rimraf": "^3.0.2",
    "typescript": "^4.5.5",
    "typescript-plugin-css-modules": "^3.4.0",
    "whatwg-fetch": "^3.6.2"
  },
  "lint-staged": {
    "**/*": "prettier --write --ignore-unknown"
  },
  "husky": {
    "hooks": {
      "pre-push": "npm run test:ci"
    }
  },
  "targets": {
    "default": {
      "distDir": "./build"
    }
  },
  "parcel-namer-custom": {
    "index.ts": "hmd.[type]"
  }
}
